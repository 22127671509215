@font-face{
  font-family: 'CerebriSansPro-Regular';
  src: local('CerebriSansPro-Regular'), url('/fonts/CerebriSansPro-Regular.ttf') format('truetype');
}

@font-face{
  font-family: 'CerebriSansPro-Medium';
  src: local('CerebriSansPro-Regular'), url('/fonts/CerebriSansPro-Medium.ttf') format('truetype');
}

@font-face{
  font-family: 'CerebriSansPro-Bold';
  src: local('CerebriSansPro-Bold'), url('/fonts/CerebriSansPro-Bold.ttf') format('truetype');
}

html,
body, header {
  background-color: #131313;
  padding: 0;
  margin: 0;
  font-family: CerebriSansPro-Regular, serif;
}
* {
  box-sizing: border-box;
}
main{
  min-height: 80vh;
  padding: 0 3rem!important;
}

h3 {
  padding: 1rem 0;
}

h3 span.category{
  font-size: 0.8rem;
  font-style: italic;
  margin-left: 20px;
}
h1{
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
.product-card{
  transition: 0.3s ease-out;
}
.product-card:hover {
  transform: translateY(-20px);
}

.product-card a {
  color:white;
}
.product-card a:hover {
  color:#2a9fd6;
  text-decoration: none;
}

.list-group-item{
  background-color: transparent;
  border:0;
}

.rating span{
  margin: 0.1rem
}

.video-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.video-caption{
  align-self: center;
  position: absolute;
  text-align: center;
  line-height: 1.2;
  font-family: 'CerebriSansPro-Bold';
  font-size: 48px;
  color: white;
  max-width: 520px;
}
.video-wrapper img
{
 display: none;
}
.fixed-top{
  animation:slide-down .7s;
}
@keyframes slide-down {
  0% {
      opacity: 0;
      transform: translateY(-100%);
  } 
  100% {
      opacity: 0.9;
      transform: translateY(0);
  } 
}
.navbar-expand-lg .navbar-nav .nav-link{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.navbar.bg-dark{
  border: 0;
}
.navbar.bg-light{
  background-color: #fff!important;
}
.howto{
  border:0;
}
footer{
  background-color: #060606;
}
.alt__view footer{
  background-color: #fff;
}
a {
  font-family: 'CerebriSansPro-Medium';
  color:#adafae;
}

a.footer-links{
  color:#adafae;
}

a.footer-links:hover{
  color: #5bb62b;
  text-decoration: none;
}

.aboutUs, .faq,.terms{
  margin:0 100px;
  line-height: 26px;
  font-size: 16px;
}

.terms span{
  font-size:12pt;
  font-weight:400;
  vertical-align:baseline;
  white-space:pre;
  white-space:pre-wrap;
}

.terms p{
  line-height:1.2;
  margin-top:0pt;
  margin-bottom:0pt;
}

.terms ol{
  margin-top:0;
  margin-bottom:0;
  padding-inline-start:48px;
}

.terms li{
  list-style-type:decimal;
  text-decoration:none;vertical-align:baseline;white-space:pre;
}
.terms li>p>span{
  font-size:12pt;
  font-weight:700;
  font-style:normal;
  font-variant:normal;
  text-decoration:none;
  vertical-align:baseline;
  white-space:pre;
  white-space:pre-wrap;
}
.btn-primary{
  background-color: #5bb62b !important;
  font-weight: 600;
  font-size: 16px;
  border:0;
}
.btn-primary:hover {
  background-color: #478f22 !important;
}

.btn-primary.active {
  background-color: #478f22 !important;
  border-color: #5bb62b !important;
}

.btn-primary:focus {
  border-color: #478f22 !important;
  box-shadow:0 0 0 .2rem rgba(70, 137, 33, 0.5)!important;
}
.card-title:hover{
  color: #5bb62b!important;
}

i.bi{
  margin-right: 10px;
  margin-bottom: 20px;
}
/* .rbt{
  width: 33.33%;
} */

.rbt-menu .dropdown-item:hover{
  background-color: #5bb62b!important;
}
.nav-link.active{
  color: #5bb62b!important;
}

.nav-link:hover{
  color: #5bb62b!important;
}

.icons {
  width: 140px;
  height: 140px;
}

.bar-of-progress{
  z-index: 20;
}
.alt__view{
  background-color: white;
  color:#060606!important;
}
.alt__view * h1, 
.alt__view * h2, 
.alt__view * h3, 
.alt__view * h4, 
.alt__view * h5, 
.alt__view * h6{
  color:#060606!important;
  font-family: CerebriSansPro-Bold;
}
.genius__banner{
  display: flex;
  padding-top:30px;
}
.genius__banner__row{
  position: relative;
}
.genius__banner__row:before{
  content:"";
  background-color: #F5F5F5;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX( -50%);
  height: 100%;
  width: 100vw;
}
.genius__banner .left{
  width:50%;
}

.genius__banner .right{
  width:50%;
}

.genius__banner .right img{
  width: 100%;
}
.genius__card__container{
  margin-top:60px;
}
.genius__card{
  border:none;
}

.genius__footer{
  background-color:#fefefe;
}
.genius__footer::before{
  content:"";
  background-color: #F5F5F5;
  position: absolute;
  left: 50%;
  transform: translateX( -50%);
  height: 100%;
  width: 100vw;
}

.genius__line{
  border:1px solid gray;
  width:28px;
  height:28px;
  position:relative;
}
.genius__line:after{
    content:"";
    position:absolute;
    border-top:1px solid red;
    width:40px;
    transform: rotate(45deg);
    transform-origin: 0% 0%;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color:#adafae;
  text-align: left;
  background-color: #000;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.accordion-button:focus{
  outline: 0;
}

.accordion-button{
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-button:after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}
.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  transform: rotate(-180deg);
}
.accordion-button:not(.collapsed) {
  /* color: #0c63e4;
  background-color: #e7f1ff; */
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:not(.collapsed)::after{
  filter: brightness(0%) invert(100%)
}
.infinite-scroll-component {
  overflow: hidden!important;
}

.nav-pills .nav-link, .nav-tabs .nav-link {
  background-color: #131313;
  /* border-color: #282828; */
}

input[type="button"].nav-link{
  outline: none
}

@media (max-width: 992px){
    .video-caption{
      display: none;
  }
  .video-wrapper img
  {
    display: block;
    object-fit: cover;
    width: 100%;
  }
  video#hero-video {
    display: none !important;
    visibility : hidden !important;
  }
  .container-fluid{
    padding:0;
  }
  .video-wrapper{
    margin:0 -50px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link{
    padding: .5rem 1rem !important;
  }
  .aboutUs, .faq,.terms{
    margin:0;
  }

}

@media (max-width: 600px){
  .video-caption
  {
    display: none;
  }
  .video-wrapper img
  {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 250px;
  }
  video#hero-video  {
    display: none !important;
    visibility : hidden !important;
  }

  .col-sm-6{
    padding: 0 10px;
  }
  .video-wrapper{
    margin:0 -50px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link{
    padding: .5rem 1rem !important;
  }
  .brand{
    margin-right: 0;
  }
  .aboutUs, .faq,.terms{
    margin:0;
  }
  .product-card:hover {
    transform: translateY(0);
  }
  .card-body{padding:0.5rem}
  
  .card-title{margin-bottom:0!important}
  .rbt{
    width: 100%;
  }

  .genius__banner{
    display: grid;
  }

  .genius__banner .left{
    width:100%;
  }
  
  .genius__banner .right{
    width:100%;
  }
}

